* {
    padding: 0;
    margin: 0;
}

#result-card {
    background-image: url('../assets/background.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;

    section {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        border-radius: 5px;
        margin-top: 50px;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 500px;
        width: 480px;
    }

    h2 {
        text-align: center;
        font-weight: bold;
        font-size: 2.5rem;
        color: white;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    p {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: yellow;
    }

    .percent-result{
        color: white;
        font-size: 1.5rem;
        margin: 1.5rem;
    }

    .timer-result{
        color: grey;
        font-size: 1.5rem;
        margin-bottom: 3rem;
    }

    .end-button-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .end-buttons {
        color: #fff;
        cursor: pointer;
        border-radius: 30px;
        padding: 1rem;
        text-align: center;
        text-decoration: none;
        transition: 0.3s linear all;
        width: 180px;
        margin-top: 3rem;
    }

    #check-answer {
        background-color: rgb(125, 47, 228);
        font-weight: bold;
        padding: 25px;
    }

    #check-answer:hover {
        background-color: lighten(rgb(125, 47, 228), 20% );
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    }

    #end-game {
        background-color: blue;
        font-weight: bold;
        padding: 25px;
    }

    #end-game:hover {
        background-color: lighten(blue, 20%);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    }
}

@media only screen and (max-width: 600px) {
    #result-card section {
        height: 450px;
        width: 380px;
        padding-left: 0rem;
        padding-right: 0rem;
    }

    #result-card .timer-result{
        color: grey;
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    #result-card .end-button-container{
        width: 380px;
        display: flex;
        justify-content: space-between;
        margin-left: 0px;
    }

    #result-card .end-buttons {
        color: #fff;
        cursor: pointer;
        border-radius: 30px;
        padding: 0.5rem;
        text-align: center;
        transition: 0.3s linear all;
        width: 180px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    #result-card #check-answer {
        padding: 25px 0;
        text-align: center;
    }

    #result-card #end-game {
        padding: 25px 0;
        text-align: center;
    }
}