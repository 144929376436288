@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Quicksand', sans-serif;
}

#home {
    background-image: url('../assets/background.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    height: 100vh;
    width: 100vw;

    section {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        margin-top: 50px;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 500px;
        width: 480px;
    }

    .cube {
        font-size: 8rem;
        color: orange;
    }

    h1 {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        color: white;
        padding-bottom: 2rem;
    }

    .play-button-container {
        width: 100%;
    }

    a.play-button {
        background-color:green;
        border-radius: 30px;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: 1rem;
        text-align: center;
        margin-top: 2rem;
        transition: 0.3s linear all;
    }

    a.play-button:hover {
        background-color: lighten(green, 20%);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    }
}

@media only screen and (max-width: 600px) {
    #home section{
        padding: 0, 1rem;
        height: 500px;
        width: 380px;        
    }

    #home .play-button-container {
        display: flex;
        justify-content: center;
    }

    #home a.play-button {
        background-color:green;
        width: 280px;
        border-radius: 30px;
        color: white;
        font-size: 2rem;
        font-weight: bold;
        text-decoration: none;
        display: block;
        cursor: pointer;
        padding: 1rem;
        text-align: center;
        margin-top: 2rem;
        transition: 0.3s linear all;
    }

}
